import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { LinksFunction } from "@remix-run/node";

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";
import "@flpstudio/design-system/global.css";

import tailwindBaseStyle from "./tailwind.css?url";

import { ThemeProvider, theme } from "@/styles/theming";
import { FullPageError } from "@/components/organisms/Error/FullPageError";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/manifest.webmanifest" />
        <link rel="icon" href="/favicon.ico" sizes="32x32" />
        <link rel="icon" type="image/svg+xml" href="/favicon/favicon.svg" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/favicon.png"
        />
        <title>GuideStack</title>
        <meta property="og:site_name" content="GuideStack" />
        <meta property="og:image" content="/favicon/favicon.png" />
        <meta property="og:locale" content="en_US" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <FullPageError />;
};

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Outlet />
    </ThemeProvider>
  );
}

export const links: LinksFunction = () => [
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap",
  },
  { rel: "stylesheet", href: tailwindBaseStyle },
];
